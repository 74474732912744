import Card from 'react-bootstrap/Card';
import './InitiativeCard.css';
import {useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import { FaLongArrowAltRight } from 'react-icons/fa';

function InitiativeCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    if( props.isPackage ){
      if( props.selected_enrolled_user_id ){
        navigate(`/user/initiatives/${props.id}/package/levels?discover=0`);
      }else{
        navigate(`/user/initiatives/${props.id}/package/levels/`);
      }
    }else{
      if( (props.tracking_type && props.tracking_type == 'single') || props.level ){

        if( props.selected_enrolled_user_id ){
          navigate(`/user/initiative/${props.id}/details/enrolled/${props.selected_enrolled_user_id}`,
          
          {
            state: {
              start_date: props.start_date,
              end_date: props.end_date,
              item: props,
            },
          });
        }else{
          navigate(`/user/initiatives/${props.id}/details`);
        }
      }
    }
  }
  return (
    <Card className="initiative-card text-center" onClick={handleClick}>
      <Card.Img variant="top" src={ props.image ? props.image : '/initiative/initiative.jpg' } />
      <Card.Body className="mt-3">
        <Card.Title className="initiative-card-title">
          {props.name.length > 25 ? props.name + ' ...' : props.name }
        </Card.Title>
        <Card.Text className="initiative-provider">{props.provider_name}</Card.Text>
        <Card.Text className="mb-2 initiative-rate">
          {!props.isPackage ? 
          <div className="Date d-flex justify-content-around pl-3 pr-3">
            <div className="Start">
              <p className="m-0">{t('Start')}</p>
              <p>{props.start}</p>
            </div>
            <div>
            <FaLongArrowAltRight color="#07b2bd" size={25} />
            </div>
            <div className="End">
              <p className="m-0">{t('End')}</p>
              <p>{props.end}</p>
            </div>
          </div>
          : '' }
          <div className="Price">{props.price ? ((props.price + ' ' + props.user_currency) ?? ' USD') : t('Free') }</div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default InitiativeCard;
