import {Container} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserInitiativesList from './UserInitiativesList';
import UserInitiativesDiscover from './UserInitiativesDiscover';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UserTrackingList from './UserTrackingList';
import UserTrackingsDiscover from './UserTrackingsDiscover';
const UserTrackings = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(queryParams.get('tab') || 'userTackingSystems');

  const handleSelect = (selectedTab:any) => {
    setActiveTab(selectedTab);
    navigate(`?tab=${selectedTab}`);
  };

  useEffect(() => {
    const tabFromQueryParam = queryParams.get('tab');
    if (tabFromQueryParam) {
      setActiveTab(tabFromQueryParam);
    }
  }, [location.search]);

  return (
    <Container>
      <h1 className="page-title">Follow-up Systems</h1>

      {/* User Courses Tabs */}
      <Tabs
       
        id="uncontrolled-tab-example"
        className="custom-web-tabs mb-5"
        activeKey={activeTab} onSelect={handleSelect}
        >
        <Tab eventKey="userTackingSystems" title="My Follow-up Systems">
         <UserTrackingList />
        </Tab>
        <Tab eventKey="nurabiTrackingSystems" title="Discover Systems">
          <UserTrackingsDiscover />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default UserTrackings;
