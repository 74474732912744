export const getUserCountry = () => {
  
    let country =''
    let time_zone =  Intl.DateTimeFormat().resolvedOptions().timeZone
    if( time_zone == 'Africa/Cairo' ){
        country = 'Egypt'
    }else{
        country = 'America'
    }
    console.log('user country' , country )
    
    
    return country
  
};
