import {Col, ProgressBar, Row} from 'react-bootstrap';
import RateStars from '../../UI/rate-stars/RateStars';
import {CourseFeedBacksProp} from './interfaces/ICourses';
import Comments from './components/Comments';
import './style/CourseFeedback.css';

const CourseFeedback = ({
  ratings,
  rating_stars,
  feedback,
}: CourseFeedBacksProp) => {
  return (
    <div className="mt-0 courses-feedback">
      <h3 className="section-title mb-3">Feedback</h3>

      <p className="courseRate">
        <span style={{color: '#fb5456'}}>{ratings}</span> Course rate
      </p>
      {rating_stars?.map((i, index) => (
        <Row className="d-flex all-rates justify-content-between">
          <Col xs={{span: 6}} md={{span: 8}}>
            <div className="progress-bar">
              <ProgressBar now={i * 10} />
            </div>
          </Col>

          <Col xs={{span: 6}} md={{span: 4}}>
            <div className="d-flex align-items-center">
              <div className="stars">
                <RateStars rateValue={5 - index} />
              </div>

              <div className="starts-percentage mx-2">{i}%</div>
            </div>
          </Col>
        </Row>
      ))}
      {feedback?.map(i => (
        <Comments comment={i} />
      ))}
    </div>
  );
};

export default CourseFeedback;
