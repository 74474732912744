import Card from 'react-bootstrap/Card';
import './InitiativeCard.css';
import {useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';

function FollowupCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    navigate('/coming-soon');
  }
  return (
    <Card className="initiative-card text-center" onClick={handleClick}>
      <Card.Img variant="top" src={ props.image ? props.image : '/initiative/initiative.jpg' } />
      <Card.Body className="mt-3">
        <Card.Title className="initiative-card-title">
          {props.name}
        </Card.Title>
        <Card.Text className="initiative-provider">{props.provider_name}</Card.Text>
        <Card.Text className="mb-2 initiative-rate">
          <div className="Price">{props.price ? ((props.price + ' ' + props.user_currency) ?? ' USD') : t('Free') }</div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default FollowupCard;
