import { useEffect, useState } from 'react'
import classes from '../style/ActivitiesTab.module.css'
import { Card, Col, Container, Row } from 'react-bootstrap'
import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import { FaRegCheckSquare } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getDomain } from '../../../helpers/getDomain';
import { colors } from '../../../constants/colors';
const RankingTab = ({userId} : any ) => {
    const [rankingList, setRankingList] = useState([]);
    const {id} = useParams();
    
    

    const getMembers = async () => {
        
        let token = localStorage.getItem('token')
        //let userId = localStorage.getItem('id')
    
        let currentUserId = userId
        try {
          let result = await axios({
            url:
              
              `${getDomain()}/api/trackingsystem/get/user/trackings/${id}/general/ranking?current_user_id=${currentUserId}`,
            method: 'get',
    
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('rankList', result.data.data);
          if (result.data.status) {
            setRankingList(result.data.data);
          }
        } catch (error) {
          console.log('gugu', error);
        }
      };

      useEffect(() => {
        getMembers();
      }, []);
   
    

  return (

    <Container>
        <Row>

        {rankingList.map((item:any, i) => {
            return (
            <Col md="12" className='mt-4'>   
                <Card style={{ padding: '1rem' }}>
                    <div className='d-flex justify-content-between align-items-center'  >
                        <div className='mt-3'>
                            <span 
                            style={{ 
                                color: 'rgb(251, 84, 86)',
                                fontWeight: 'bold',
                                fontSize: '19px',
                            }}>{i+1}</span>
                            <span style={{ position: 'relative' , margin: '0 20px', }}>
                                <img src='/icons/man.png' style={{ borderRadius: '50%' , width: '60px' }} />
                                { i== 0 ?
                                <img src='/icons/firstTag.png' 
                                style={{ 
                                    width: '30px',
                                    position: 'absolute',
                                    top: '-35px',
                                    left: '25px',
                                 }} />
                                 : '' }

                            </span>
                            <span
                            style={{
                                fontSize: '20px',
                                fontWeight: '500',
                                color: item?.me ? colors.orange : '#000'
                            }}  
                            >{item?.name} {item?.me ? '(You)' : '' } </span>
                        </div>
                        <div>
                          <CircleProgress  value={ item.score ? item.score.toFixed(1) : 0 }/>
                        </div>
                    </div>

                </Card>
            </Col>

        );
        })}

            

        </Row>

    </Container>
  );
}

export default RankingTab;
