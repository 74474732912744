import Card from 'react-bootstrap/Card';
import courseImg from '../images/courses/course-img.png';
import CourseCardLink from './course-card-link/CourseCardLink';
import RateStars from './rate-stars/RateStars';
import './CourseCard.css';
import {useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';

function CourseCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
   if( props.is_user == 0 ){
    navigate('/login')
   }else{
    if( props.is_enrolled ){
      navigate(`/lessons/${props.id}`, {state: {course: props}})
    }else{
      navigate(`/courses/${props.id}`, {state: {course: props}})
    }
   }
  }

  return (
    <Card className="course-card" onClick={handleClick}>
      <Card.Img variant="top" src={ props.image ? props.image : courseImg } />
      <Card.Body>
        <Card.Title className="course-card-title mb-3 text-center">
          {props.title}
        </Card.Title>
        <p className='text-muted text-center'>
          {props.short_short_description ? props.short_short_description.slice(0,40) : '' }
          {props.short_short_description && props.short_short_description.length > 40 ? ' ...' : '' }
        </p>
        <Card.Text className='text-center' style={{ color: '#08cad7', fontWeight: 'bold' }}>{props.provider_name}</Card.Text>
        <Card.Text className='text-center' style={{color: '#fb5456' , fontSize: '1.1rem' , fontWeight: 'bold'}}>
        <div className="Price">{props.price ? ((props.price + ' ' + props.user_currency) ?? ' USD') : t('Free') }</div>
        </Card.Text>
        
        
      </Card.Body>
    </Card>
  );
}

export default CourseCard;
